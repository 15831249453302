.ant-picker-calendar-mode-switch {
    display: none;
}

@media screen and (max-width: 1000px) {
    .ant-badge-status-text {
        display: none;
    }
}

.ant-picker-calendar-date-content {
    height: 80px !important;
}

@media screen and (max-width: 500px) {
    .ant-picker-calendar-date-content {
        height: 60px !important;
    }
}

.ant-picker-calendar-header {
    margin-top: -2px;
    margin-right: 56px;
}
