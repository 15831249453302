.home-page-second-part {
    padding: 2% 5%;
    background-color: #f2fcff;
    min-height: 70vh;
}

.part-title {
    color: white;
    font-size: var(--landingPageTitleFontSize);
    font-weight: 700;
    font-family: var(--titleFont);
    background-color: #007bff;
    margin-bottom: 100px;
    padding: 0 10px;
    border-radius: 5px;
    text-align: center;
}

.parts {
    width: 100%;
    margin-top: 50px;
    justify-content: space-around;
    align-items: flex-start;
}

.icon {
    width: 50%;
}

.part {
    text-align: center;
    width: 25%;
}

.title {
    font-size: 2.3em;
    font-weight: 600;
    font-family: var(--titleFont);
}

.description {
    font-size: var(--landingPageFontSize);
}

@media (max-width: 768px) {
    .home-page-second-part {
        padding: 10% 2%;
    }

    .icon {
        width: 40%;
    }

    .part-title {
        font-size: var(--landingPageTitleFontSizeSmall);
        font-weight: 600;
        margin: 0 10px;
    }

    .part-title {
        margin-bottom: 50px;
    }

    .parts {
        margin-top: 0px;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .part {
        width: 90%;
        gap: 10px;
    }

    .title {
        font-size: var(--landingPageTitleFontSizeSmall);
    }

    .description {
        font-size: var(--landingPageFontSizeSmall);
    }
}