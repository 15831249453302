.ant-select-disabled .ant-select-selector {
    background-color: inherit !important;
    cursor: default !important;
    color: inherit !important;
}

.ant-select-disabled .ant-select-arrow {
    display: none;
}

.ant-select-selection-item {
    text-align: left;
}

.select-pet .ant-select-selector {
    border-radius: 25px;
}