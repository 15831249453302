.activity-select .ant-select-selector {
    background-color: rgba(0, 0, 0, 0);
}

.number-select {
    width: 100%;
}

.number-select:hover {
    background-color: rgba(197, 194, 194, 0.5);
    width: 100%;
    border-radius: 10px;
}

.number-select.active {
    background-color: rgba(197, 194, 194, 1);
    border-radius: 10px;
}

.drawer-activity-btn {
    overflow: hidden;
}

.activity-btn-launch {
    /* box-shadow: 0 1px 7px rgba(0, 0, 0, 0.5); */
    box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.4);
}

.activity-btn-launch:hover {
    transform: scale(1.04);
}

.activity-btn-launch:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(0px, 1px);
}
