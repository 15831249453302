@media screen and (max-width: 500px) {
    .navbar-app-logo {
        width: 60px;
    }
}

@media screen and (max-width: 400px) {
    .navbar-item-left {
        margin-left: 10px !important;
    }
    .navbar-item-right {
        margin-right: 10px !important;
    }
}