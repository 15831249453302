.health-record-btn:hover {
    background-color: rgba(197, 194, 194, 0.5);
    border-radius: 10px;
}

@media screen and (max-width: 440px) {
    .health-record-btn-text {
        font-size: 12px !important;
    }
    .health-record-section-container {
        gap: 20px !important;
    }
}

.health-record-drawer .ant-drawer-title {
    display: flex;
    gap: 10px;
    font-weight: normal;
}

@media screen and (max-width: 500px) {
    .health-card-drawer-pet-picture {
        margin-right: 50px !important;
    }

    @media screen and (max-width: 400px) {
        .health-card-drawer-pet-picture {
            margin-right: 25px !important;
        }

        @media screen and (max-width: 360px) {
            .health-card-drawer-pet-picture {
                margin-right: 0px !important;
            }
        }
    }
}
