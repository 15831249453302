.sidebar-data .ant-menu-title-content {
    padding: 0px 15px;
}

.sidebar-menu.sider .ant-menu-item-selected.sidebar-menu-item {
    width: 250px !important;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.sidebar-menu.sider .ant-menu-item-selected.sidebar-menu-item {
    font-weight: bold;
}

.sidebar-menu.sidebar-menu.footer .ant-menu-item.sidebar-menu-item .ant-menu-title-content {
    display: none !important;
}

.sidebar-menu.sidebar-menu.footer .ant-menu-item-selected.sidebar-menu-item {
    border-radius: 10px;
}

.sidebar-menu.sidebar-menu.footer .ant-menu-item.sidebar-menu-item {
    border-radius: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}
