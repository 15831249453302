.ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.ant-drawer-title {
    font-size: 20px !important;
}

.ant-picker input {
    cursor: default !important;
    color: black !important;
}

.calendar-number-input .ant-input-number-input-wrap {
    border-radius: 5px;
    background-color: white !important;
}

.calendar-number-input .ant-input-number-group-addon {
    background-color: rgb(237, 237, 237) !important;
    color: black !important;
    cursor: default !important;
}