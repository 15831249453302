.home-page-sixth-part {
    min-height: 70vh;
    background-color: #f2fcff;
    padding: 75px 0;
}

.sponsor-image {
    width: 20%;
}

.sponsor-image-second {
    width: 30%;
}

@media (max-width: 768px) {
    .home-page-sixth-part {
        padding: 25px 0;
    }

    .sponsor-image {
        width: 30%;
    }

    .sponsor-image-second {
        width: 40%;
    }
}