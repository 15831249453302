.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#info-container {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  border-radius: 15px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.ant-card-head {
  border-bottom: none !important;
  margin-bottom: 0;
}

.ant-card-head-title {
  text-align: left !important;
  font-size: 16px;
}

.card-title-text {
  display: flex;
  margin-left: -10px;
  font-weight: bold;
  font-size: 16px;
}

@media screen and (max-width: 700px) {
  .ant-card-head-title {
    font-size: 13px;
  }
  .card-title-text {
    display: flex;
    margin-left: -10px;
    font-weight: bold;
    font-size: 14px;
  }
}

.ant-card-body {
  margin-top: -16px;
  margin-bottom: -16px;
}
