.home-page-third-part {
    min-height: 90vh;
    background-color: #8de0ff;
    padding: 75px 12.25%;
}

.third-part-paragraph {
    font-size: var(--landingPageFontSize);
    color: black;
    margin-bottom: 15px;
    max-width: 70%;
}

.third-part-screenshot {
    width: 70%;
    border: solid 3px black;
    border-radius: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.community-path-image {
    width: 100%;
    margin-top: 50px;
    background-color: white;
    padding: 50px;
    border-radius: 70px;
}

@media (max-width: 768px) {
    .home-page-third-part {
        padding: 25px 5%;
    }

    .third-part-paragraph {
        font-size: var(--landingPageFontSizeSmall);
        max-width: 100%;
        text-align: center;
    }

    .community-path-image {
        width: 100%;
        margin-top: 50px;
        background-color: white;
        padding: 0px;
        border-radius: 50px;
    }
}