.ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.ant-drawer-title {
    font-size: 20px !important;
}

.ant-input-number-input-wrap input {
    cursor: default !important;
    color: black !important;
}

@media screen and (max-width: 450px) {
    .pet-container-responsive {
        flex-direction: column !important;
        width: 100%;
        gap: 10px !important;
    }
    .pet-content-responsive {
        width: 100% !important;
    }
}

.vet-info .ant-input-group-addon {
    color: black !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    cursor: default !important;
}

.vet-info .ant-input {
    color: black !important;
    background-color: rgb(255, 255, 255) !important;
    cursor: default;
}

.pet-modification.disabled .ant-radio-wrapper-checked .ant-radio .ant-radio-inner{
    background-color: rgb(26, 117, 250);
    border-color: rgb(26, 117, 250);
}

.pet-modification.disabled .ant-radio-wrapper-checked .ant-radio .ant-radio-inner::after{
    background-color: white;
}

.pet-modification.disabled .ant-radio-wrapper,
.pet-modification.disabled .ant-radio-input,
.pet-modification.disabled .ant-radio-inner {
    cursor: default !important;
}