.header-nav {
    width: 100%;
    height: 100px;
    flex-direction: row;
    background: var(--gradientBackground);
}

.header-app-access {
    text-align: left;
    white-space: nowrap;
    border: 1px solid #fff;
    border-radius: 40px;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 130px;
    height: 20px;
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    color: white;
}

.header-app-access:hover {
    font-size: 14px;
    background-color: white;
    color: var(--blueBackgroundTheme);
}

.header {
    background: var(--gradientBackground);
}

.header-content {
    width: 100%;
    height: 600px;
}

.header-content-text {
    font-family: poppins-semibold,poppins,sans-serif;
    font-size: clamp(16px, 5vw, 55px);
    font-weight: bold;
    line-height: 1.3em;
    margin-left: 30px;
    max-width: 850px;
}

.mission-content {
    height: auto;
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
}

.mission-content-title {
    font-size: clamp(18px, 5vw, 20px);
    font-weight: 600;
    height: 24px;
    font-family: avenir-lt-w01_85-heavy1475544,sans-serif;
    letter-spacing: 0.2em;
    color: white;
    background-color: rgb(8, 121, 250);
    max-width: 231px;
    width: 231px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.mission-content-text {
    text-align: center;
    color: black;
    font-family: poppins-semibold,poppins,sans-serif;
    font-weight: 600;
    max-width: 1500px;
    margin: 10px;
    min-height: 84px;
    font-size: clamp(15px, 5vw, 30px);
}

.join-us-bg {
    background: var(--gradientBackground);
}

.join-us .title {
    font-weight: bold;
    font-size: clamp(30px, 5vw, 36px);
}

.join-us .images .text {
    font-weight: bolder;
    text-align: center;
    font-size: clamp(20px, 2vw, 24px);
}

.how-it-works .title {
    background: var(--gradientBackground);
}

.how-it-works .title .text {
    font-weight: bolder;
    text-align: center;
    font-size: clamp(15px, 5vw, 32px);
}

.lets-go .text {
    font-size: clamp(45px, 5vw, 66px);
}

@media screen and (max-width: 500px) {
    .header-logo {
        width: 80px;
    }
    .header-app-access {
        white-space: nowrap;
        width: 130px;
        height: 20px;
        padding: 10px 20px;
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;
    }
}

@media screen and (max-width: 462px) {
    .header-content-flex-direction {
        gap: 5px;
        align-items: center;
        flex-direction: column !important;
    }
    .header-content-text {
        margin-left: 0px;
        text-align: center !important;
        font-size: clamp(25px, 5vw, 55px);
        font-weight: bold;
        line-height: 1.3em;
        max-width: 706px;
    }
}

@media screen and (max-width: 380px) {
    .header-nav-input {
        gap: 5px !important;
    }
}

@media screen and (max-width: 1200px) {
    .join-us .images {
        flex-direction: column !important;
    }
    .join-us .images .image {
        width: calc(100% - 20px) !important;
    }
}

@media screen and (max-width: 430px) {
    .join-us .images .image {
        gap: 60px;
    }
}

@media screen and (max-width: 440px) {
    .join-us-bg {
        max-height: 400px !important;
    }
}

