.home-page-seventh-part {
    min-height: 70vh;
    background: linear-gradient(135deg, var(--blueBackgroundTheme) 33%, var(--lighterBludBackgroundTheme) 100%);
    height: fit-content;
}

.seventh-part-content {
    padding-left: 10%;
    height: 100%;
}

.seventh-part-illustration {
    width: 50%;
}

.seventh-part-email {
    font-size: 1.5em;
    font-weight: 700;
    color: white;
}

@media (max-width: 768px) {
    .home-page-seventh-part {
        padding: 0 25px;
    }

    .seventh-part-content {
        padding-left: 0;
    }
}