.change-lang .ant-select-selector {
    border-radius: 25px;
    border: 1px solid #fff !important;
    background-color: transparent !important;
}

.change-lang .ant-select-selector:hover {
    border: 1px solid #fff;
}

.change-lang .ant-select-selection-item .change-lang-text {
    font-weight: bold;
    color: white;
}

@media screen and (max-width: 470px) {
    .change-lang .ant-select-selection-item .change-lang-text {
        display: none;
    }
    .change-lang {
        width: 67px !important;
    }
}

.change-lang .ant-select-arrow {
    color: #fff;
}

.lang-button {
    height: 50px;
    cursor: pointer;
    transition: transform ease-in 0.2s;
}

.lang-button:hover {
    transform: scaleX(1.1) scaleY(1.1);
}