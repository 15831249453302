@media screen and (max-width: 760px) {
    .profile-container-responsive {
        flex-direction: column !important;
        width: 100%;
    }
    .profile-content-responsive {
        width: 100% !important;
    }
}

.profile-tabs .ant-tabs-tab {
    border-bottom: solid 1px ;
}

.profile-tabs .ant-tabs-nav-wrap {
    border-bottom: 1px solid #E6F7FF;
}
