.firstSummaryContent {
    padding: 10px;
    min-height: 45vh;
}

.behaviorSummary {
    min-height: 40vh;
    border: solid 2px #717171;
    border-radius: 5px;
    padding: 10px;
    width: 50%;
    background-color: white;
}

.vaccineSummary {
    height: 100%;
    border: solid 2px #717171;
    border-radius: 5px;
    padding: 10px;
    width: 50%;
    min-height: 40vh;
    background-color: white;
}

.secondSummaryContent {
    padding: 10px;
    min-height: 40vh;
}

.pointSummary {
    height: 100%;
    border: solid 2px #717171;
    border-radius: 5px;
    padding: 15px;
    width: 33%;
    background-color: white;
}

.weightSummary {
    height: 100%;
    border: solid 2px #717171;
    border-radius: 5px;
    padding: 15px;
    width: 33%;
    background-color: white;
}

.activitySummary {
    height: 100%;
    border: solid 2px #717171;
    border-radius: 5px;
    padding: 15px;
    width: 33%;
    background-color: white;
}