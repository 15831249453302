.home-page-fourth-part {
    min-height: 70vh;
    background-color: white;
    padding: 75px 12.25%;
}

.fourth-part-content {
    margin-top: 50px;
}

.fourth-part-illustration {
    border-radius: 50px;
    width: 40%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.fourth-part-paragraph {
    font-size: var(--landingPageFontSize);
    color: black;
    margin-bottom: 15px;
    max-width: 70%;
}

@media (max-width: 768px) {
    .home-page-fourth-part {
        min-height: 70vh;
        background-color: white;
        padding: 25px 7.5%;
    }

    .fourth-part-content {
        margin-top: 10px;
    }

    .fourth-part-illustration {
        width: 100%;
        margin-bottom: 20px;
    }

    .fourth-part-paragraph {
        font-size: var(--landingPageFontSizeSmall);
        max-width: 100%;
        text-align: center;
    }
}