.home-header {
    background-color: white;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: rgba(17, 12, 46, 0.1) 0px 24px 50px 0px;
    gap: 250px;
}

@media (max-width: 768px) {
    .home-header {
        gap: 0px;
    }
}