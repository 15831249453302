.behaviors {
    margin-top: 0;
    margin-bottom: 10px;
}

.behaviors-button {
    border: none;
    background-color: rgb(179, 179, 179);
    border-radius: 20px;
    padding: 20px 30px;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
}

@media screen and (max-width: 700px) {
    .behavior-analysis-message {
        font-size: 15px !important;
    }
    .behaviors-button {
        padding: 10px 12px;
        font-size: 12px;
        margin: 3px;
    }
}

.behaviors-button:hover {
    background-color: rgb(189, 188, 188) !important;
    color: white;
}

.behaviors-button.active {
    background-color: #007BFF;
}

.behaviors-button.active:hover {
    background-color: #007BFF !important;
}

.behaviors-button span {
    color: white;
}

.behaviors-button.disabled:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.behaviors-button.disabled span {
    color: rgb(120, 119, 117);
}

.behaviors-detail:hover {
    background-color: white !important;
}

.behaviors-detail.disabled:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.rotate-on-hover {
    transition: transform 0.75s ease-in-out;
}

.rotate-on-hover:hover {
    transform: rotate(360deg);
}

.shake-on-active {
    animation: move 1s forwards
}

@keyframes move {
    0% {
        transform: none;
    }
    33% {
        transform: rotate(15deg);
    }
    66% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}