
.family-details {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    width: 100%;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.family-details.visible {
    max-height: 1000px;
    opacity: 1;
}