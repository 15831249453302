.home-page-first-part {
    background-image: url('../../../../public/assets/images/landingPage/HomePageFirstPart.png');
    background-size: cover;
    background-position: center;
    min-height: 90vh;
    padding: 20px 5%;
}

@media (max-width: 768px) {
    .home-page-first-part {
        min-height: 70vh;
    }
}