.home-page-fifth-part {
    min-height: 70vh;
    background-color: #8de0ff;
    padding: 75px 12.25%;
}

.fifth-part-content {
    margin-top: 50px;
}

.fifth-part-illustration {
    border-radius: 20px;
    width: 30%;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.fifth-part-paragraph {
    font-size: var(--landingPageFontSize);
    color: black;
}

@media (max-width: 768px) {
    .fifth-part-content {
        margin-top: 10px;
    }

    .home-page-fifth-part {
        padding: 25px 7.5%;
    }

    .fifth-part-illustration {
        width: 100%;
    }

    .fifth-part-paragraph {
        font-size: var(--landingPageFontSizeSmall);
        max-width: 100%;
        text-align: center;
    }
}