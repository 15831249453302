.access-button {
    padding: 15px 25px;
    border: none;
    border-radius: 100px;
    font-family: var(--titleFont);
    font-size: 2em;
    font-weight: 600;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: transform ease-in-out 0.2s;
}

.access-button:hover {
    transform: translateY(-5px);
}

.blue {
    background: var(--gradientBackground);
    color: white;
}

.white {
    background: white;
    color: var(--colorPrimary);
}

.small {
    padding: 10px 20px;
    font-size: 1.5em;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 10px 20px -5px, rgba(0, 0, 0, 0.2) 0px 6px 12px -6px;
}